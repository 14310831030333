<template>
  <v-dialog
    scrollable
    v-model="dialog"
    max-width="600px"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Landing Page</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text>
        <v-form @submit.prevent="saveForm" method="post" id="landing-form">
          <v-text-field
            label="Name*"
            v-model="fields.name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('name')"
            :error-messages="errors['name']"
          ></v-text-field>

          <v-text-field
            label="Slug*"
            v-model="fields.slug"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('slug')"
            :error-messages="errors['slug']"
          ></v-text-field>

          <v-text-field
            label="Hero Heading*"
            v-model="fields.hero_heading"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('namhero_headinge')"
            :error-messages="errors['hero_heading']"
          ></v-text-field>

          <v-textarea
            label="Hero Text*"
            v-model="fields.hero_text"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('hero_text')"
            :error-messages="errors['hero_text']"
          ></v-textarea>

          <v-file-input
            label="Hero Image"
            v-model="fields.hero_image"
            accept="image/png, image/jpeg"
            placeholder="Choose aHero Image"
            outlined
            @change="heroImageHasChanged()"
            :error="errors.hasOwnProperty('hero_image')"
            :error-messages="errors['hero_image']"
          ></v-file-input>

          <v-text-field
            label="Benefits Hading*"
            v-model="fields.benefits_hading"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('benefits_hading')"
            :error-messages="errors['benefits_hading']"
          ></v-text-field>

          <tiptap
            class="mb-5"
            label="Benefits Paragraph"
            v-model="fields.benefits_paragraph"
            :error="errors.hasOwnProperty('benefits_paragraph')"
            :error-messages="errors['benefits_paragraph']"
          />

          <v-text-field
            label="Features Text*"
            v-model="fields.features_text"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('features_text')"
            :error-messages="errors['features_text']"
          ></v-text-field>

          <v-select
            label="Status *"
            v-model="fields.status"
            :items="statuses"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('status')"
            :error-messages="errors['status']"
          ></v-select>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="landing-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Tiptap from "@/views/components/Tiptap.vue";

export default {
  components: {
    Tiptap,
  },

  data() {
    return {
      fields: {
        name: null,
        hereo_image_has_changed: false,
        status: null,
        slug: null,
        hero_heading: null,
        hero_text: null,
        benefits_hading: null,
        benefits_paragraph: null,
        features_text: null,
        hero_image: null,
      },
      statuses: ["In Draft", "Published"],
      errors: {},
      dialog: false,
      loading: false,
      isEditing: false,
    };
  },

  methods: {
    heroImageHasChanged() {
      this.fields.hereo_image_has_changed = true;
    },

    openForm: function (page = null) {
      if (page !== null) {
        this.page = page;
        this.fields.name = page.name;
        this.fields.slug = page.slug;
        this.fields.hero_heading = page.hero_heading;
        this.fields.hero_text = page.hero_text;
        this.fields.hero_image = page.hero_image;
        this.fields.benefits_hading = page.benefits_hading;
        this.fields.benefits_paragraph = page.benefits_paragraph;
        this.fields.features_text = page.features_text;
        this.fields.status = page.status;

        this.isEditing = true;
      }
      this.dialog = true;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.pageId = this.$route.params.pageId;
      }

      this.$store
        .dispatch("lpb/landingpages/saveLandingPage", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.fields.name = null;
      this.fields.hereo_image_has_changed = false;
      this.fields.status = null;
      this.fields.slug = null;
      this.fields.hero_heading = null;
      this.fields.hero_text = null;
      this.fields.benefits_hading = null;
      this.fields.benefits_paragraph = null;
      this.fields.features_text = null;
      this.fields.hero_image = null;
    },
  },
};
</script>
